body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* ======================================================================= */

.App .container {
  min-width: 100%;
  height: 100vh;
}

.intro-sec {
  position: relative;
  background-color: #000000;
  z-index: 99;
  min-height: 100vh;
}

.intro_container {
  position: relative;
  height: 100%;
  z-index: 0.5;
}

.intro_sub_section {
  min-height: 100vh;
}

.heading_block {
  padding-top: 85px;
  width: 100%;
  height: 100%;
}

.logo {
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  z-index: 10;
}

.logo img {
  max-width: 84px;
}

.logo p {
  font-weight: 400;
  font-size: 9.33332px;
  line-height: 130%;
  color: #ffffff;
  margin-top: 5px;
}

.heading_block h2 {
  font-weight: 600;
  line-height: 110%;
  text-align: center;
  text-transform: uppercase;
  color: #ffd700;
  max-width: 446px;
  width: 100%;
  margin: 40px auto 0px;
  font-family: sans-serif;
  position: relative;
  z-index: 10;
}

.heading_block .intro-desc {
  text-align: center;
  max-width: 354px;
  width: 100%;
  margin: 20px auto 0px;
  font-family: sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  position: relative;
  z-index: 10;
  color: #ffffff;
}

.intro_img img {
  max-width: 100%;
  height: auto;
}

@media only screen and (max-height: 790px) {
  .intro_img {
    transform: scale(0.6);
  }
}

@media only screen and (max-height: 890px) {
  .intro_img {
    transform: scale(0.8);
  }
}

/* ======================================================================== */

.form-sec {
  background-color: #343434;
  min-height: 100vh;
  height: 100%;
}

.form_container {
  padding: 60px 4rem;
  position: relative;
  z-index: 30;
  color: #fff;
  background-color: #343434;
}

.form_desc h2 {
  font-family: sans-serif;
  font-weight: 800;
  font-size: 24px;
  line-height: 150%;
  margin-top: 45px;
}

.form_desc .p {
  font-family: sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: rgb(141, 141, 141);
  margin-top: 13px;
}

.form-sec .main_form {
  background: rgb(45, 45, 45);
  border-radius: 20px;
  margin-top: 45px;
  padding: 50px 40px;
}

.form-sec .main_form .form-label {
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
}

.text-muted {
  color: red !important;
}

.form-sec .main_form input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(118, 118, 118);
  opacity: 1; /* Firefox */
}

.form-sec .main_form input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgb(118, 118, 118);
}

.form-sec .main_form input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgb(118, 118, 118);
}

.form-sec .main_form input {
  border: 1px solid rgb(64, 64, 64);
  border-radius: 10px;
  height: 60px;
  padding: 0px 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  background: transparent;
  color: rgb(255, 255, 255);
  margin-top: 12px;
  transition: all 0.3s ease-in-out 0s;
}

.form-sec .main_form .submit_button {
  height: 60px;
  width: auto;
  background: rgb(255, 251, 31);
  border-radius: 12px;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  text-transform: uppercase;
  color: rgb(0, 0, 0);
  margin-top: 40px;
  padding: 0px 40px;
}
.form-sec .main_form .copy_button {
  height: 60px;
  width: auto;
  background: rgb(255, 251, 31);
  border-radius: 12px;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  text-transform: uppercase;
  color: rgb(0, 0, 0);
  margin-top: 40px;
  padding: 0px 40px;
}

.form-sec .main_form .submit_button:disabled {
  background: rgb(63, 63, 63);
  color: rgb(141, 141, 141);
  cursor: default;
}

.form-sec .main_form .submit_desc {
  margin-top: 20px;
  font-weight: 450;
  font-size: 12px;
  line-height: 140%;
  color: rgb(141, 141, 141);
}

.form-sec .main_form .submit_desc a {
  color: rgb(88, 238, 190);
  font-weight: bold;
  text-decoration: underline;
}

/* ======================================================================== */

@media only screen and (max-width: 992px) {
  .intro-sec {
    min-height: 90vh;
  }

  .form-sec {
    overflow: none;
    max-height: 100%;
  }
}

@media only screen and (max-width: 750px) {
  .intro_sub_section {
    min-height: 65vh;
  }

  .form_container {
    padding: 0;
  }
}

@media only screen and (max-width: 480px) {
  .form_container {
    padding: 0;
  }

  .intro_sub_section {
    min-height: 45vh;
  }
}
